/* layout
 *
 */


 // main body holder

.main-outer {
    background: color("whitesmoke");
    padding-top: 3rem;
    padding-bottom: 10rem;
}


// main document content

.document { 

    > .section {

        > h1 {
            margin-top: 0;
            margin-bottom: 4rem;
        }

        .toctree-wrapper .caption {
            @extend h2;
        }
    }
}
